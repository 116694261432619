<template>
  <!-- <content-with-sidebar
    v-if="Object.keys(faqDetails).length"
    class="cws-container cws-sidebar-right blog-wrapper"
  > -->

  <!-- content -->
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">

        <h1 v-if="!isFetchingFaqDetails && !faqDetails">
          Aucun établissement ne correspond à cet enregistrement
        </h1>
        <content-loader
          v-if="isFetchingFaqDetails"
          view-box="0 0 400 460"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <circle
            cx="29"
            cy="30"
            r="17"
          />
          <rect
            x="58"
            y="18"
            rx="2"
            ry="2"
            width="140"
            height="10"
          />
          <rect
            x="58"
            y="34"
            rx="2"
            ry="2"
            width="140"
            height="10"
          />
          <rect
            x="-7"
            y="60"
            rx="2"
            ry="2"
            width="408"
            height="132"
          />
        </content-loader>

        <div v-if="faqDetails">
          <b-card>
            <FileWidget
              v-if="faqDetails.banniere[0]"
              :type="'image'"
              :file="faqDetails.banniere[0]"
              :b-img-props="{ class:'card-img-top', width:'600', height:'400', alt: faqDetails.titre}"
            />

            <div class="d-flex justify-content-between mt-1">
              <div class="my-auto">
                <strong>{{faqDetails.titre}}</strong>
              </div>
              <div>
                <b-button
                  v-if="$can('update', 'faq')"
                  class="float-right"
                  title="Editer cetteFAQ"
                  variant="warning"
                  :to="{ name: 'faq-edit', params: { faqId: this.faqId } }"
                >
                Éditer le contenu
                  <i class="las la-pen-alt"></i>
                  <span class="text-nowrap" />
                </b-button>
              </div>
            </div>

            <hr>

            <div class="my-1 py-25">
              <b-link
                v-for="tag in faqDetails.cibleCategorie"
                :key="tag"
              >
                <b-badge
                  pill
                  class="mr-75"
                  :variant="tagsColor(tag)"
                >
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content"
              v-html="faqDetails.contenu"
            />
          </b-card>
        </div>
      </b-col>
      <!--/ blogs -->

      <!-- blog comment -->
      <!-- <b-col
          id="blogComment"
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Comment
          </h6>
          <b-card
            v-for="(comment,index) in faqDetails.comments"
            :key="index"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  :src="comment.avatar"
                  size="38"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder mb-25">
                  {{ comment.userFullName }}
                </h6>
                <b-card-text>{{ comment.commentedAt }}</b-card-text>
                <b-card-text>
                  {{ comment.commentText }}
                </b-card-text>
                <b-link>
                  <div class="d-inline-flex align-items-center">
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      size="18"
                      class="mr-50"
                    />
                    <span>Reply</span>
                  </div>
                </b-link>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col> -->
      <!--/ blog comment -->

      <!-- Leave a Blog Comment -->
      <!-- <b-col
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Leave a Comment
          </h6>
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="6">
                  <b-form-group class="mb-2">
                    <b-form-input
                      name="name"
                      placeholder="Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group class="mb-2">
                    <b-form-input
                      name="email"
                      type="email"
                      placeholder="Email"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group class="mb-2">
                    <b-form-input
                      name="website"
                      placeholder="Website"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group class="mb-2">
                    <b-form-textarea
                      name="textarea"
                      rows="4"
                      placeholder="Website"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="commentCheckmark"
                    name="checkbox-1"
                    class="mb-2"
                  >
                    Save my name, email, and website in this browser for the next time I comment.
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Post Comment
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col> -->
      <!--/ Leave a Blog Comment -->
    </b-row>
    <!--/ blogs -->
  </div>
  <!--/ content -->

  <!-- sidebar -->
  <!-- <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    > -->
  <!-- input search -->
  <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Search here"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
  <!--/ input search -->

  <!-- recent posts -->
  <!-- <div class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">
          Recent Posts
        </h6>
        <b-media
          v-for="(recentpost,index) in blogSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link>
              <b-img
                :src="recentpost.img"
                :alt="recentpost.img.slice(6)"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div> -->
  <!--/ recent posts -->

  <!-- categories -->
  <!-- <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>

        <div
          v-for="category in blogSidebar.categories"
          :key="category.icon"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor(category.category)"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.category }}
            </div>
          </b-link>
        </div>
      </div> -->
  <!--/ categories -->
  <!-- </div> -->
  <!-- </content-with-sidebar> -->
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { ContentLoader } from 'vue-content-loader'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

import faqStoreModule from '@/store/faq'
import FileWidget from '@/components/FileWidget.vue'


import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils"

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
    ContentLoader,
    FileWidget,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // search_query: '',
      // commentCheckmark: '',
      faqDetails: [],
      // blogSidebar: {},
      // socialShareIcons: ['GithubIcon', 'GitlabIcon', 'FacebookIcon', 'TwitterIcon', 'LinkedinIcon'],
      isFetchingFaqDetails: false,
      // faqDetails: null,
      faqId: null,
    }
  },

  setup() {
    const requiredStoreModules = [
      { path: 'faq', module: faqStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingFaqDetails = true
        const { faqId } = val.params
        this.faqId = faqId
        this.getFaq(faqId)
          .then(res => {
            this.faqDetails = res.data.resource
            console.log('this.faqDetails------------------------: ', this.faqDetails)
            this.isFetchingFaqDetails = false

            // console.log('faqDetails.banniere[0].url*********************: ', this.faqDetails.banniere[0]);
          })
          .catch(err => {
            this.isFetchingFaqDetails = false
          })
      },
    },
  },

  created() {
    // this.$http.get('/blog/list/data/detail').then(res => {
    //   this.faqDetails = res.data
    // })
    // this.$http.get('/blog/list/data/sidebar').then(res => {
    //   this.blogSidebar = res.data
    // })
  },

  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    ...mapActions('faq', {
      getFaq: 'getFaq',
      createFaq: 'createFaq',
      // updateEtablissement: 'updateEtablissement',
      action_getListFaq: 'getListFaq',
    }),
    onEtsValidationSuccess() {
      this.faqDetails = { ...this.faqDetails, isVerified: true }
    },
    onEtsRejectionSuccess(evt) {
      // this.faqDetails = { ...this.faqDetails, ...evt }
      this.$router.push({ name: 'faq-list' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
