<template>
  <div>
    <div
      v-if="isFile"
      class="file"
    >
      <a @click.prevent="downlodFile(file)"> <i class="las la-file-image" /></a>
      <div
        v-if="isDownloadingFile"
        class="spinnerContainer"
      >
        <b-spinner class="text-white" />
      </div>
      <a
        class="filename"
        @click.prevent="downlodFile(file)"
      >
        {{ getFileName(file) }}
      </a>
      <!-- <div class="file" /> -->
    </div>

    <div v-if="isImage">
      <div
        v-if="isDownloadingFile"
      >
        <content-loader
          view-box="0 0 400 460"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="-7"
            y="60"
            rx="3"
            ry="2"
            :width="bImgProps.width"
            :height="bImgProps.height"
          />
        </content-loader>
        <!-- <b-spinner /> -->
      </div>
      <b-img
        v-if="!isDownloadingFile"
        :src="imageSrc"
        :alt="bImgProps.alt"
        :class="bImgProps.class"
        :width="bImgProps.width"
        :height="bImgProps.height"
      />
    </div>

  </div>
</template>

<script>
import { BSpinner, BImg } from 'bootstrap-vue'
import filesService from '@/services/http/files.service'
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    BSpinner, BImg, ContentLoader,
  },
  props: {
    file: {
      type: String,
      default: '',
    },
    bImgProps: {
      type: Object,
      default: () => ({
        alt: '', class: '', width: '', height: '',
      }),
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDownloadingFile: false,
      imageSrc: '',
    }
  },

  computed: {
    isImage() {
      return this.type === 'image'
    },
    isFile() {
      return !this.type || this.type === 'default'
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(val, old) {
        // console.log('val :>> ', val);
        // console.log('file :>> ', this.file);
        if (this.isImage && this.file) {
          this.downlodFile(this.file)
        }
      },
    },
  },
  methods: {
    getFileName(file) {
      return file
      return `${file.original_filename}.${file.format}`
    },
    getCloudinaryFileName(file) {
      return `${file.original_filename}.${file.format}`
    },
    async downlodFile(file) {
      this.isDownloadingFile = true
      filesService
        .getFile({ name: file })
        .then(response => {
          const blob = new Blob([response.data], {
            type: `${file.resource_type}/${file.format}`,
          })
          const link = document.createElement('a')

          const url = URL.createObjectURL(blob)

          if (this.isImage) {
            setTimeout(() => {
              this.imageSrc = url
              this.isDownloadingFile = false
            }, 0)

            return
          }

          link.href = url
          link.download = this.getFileName(file)
          link.click() 
          URL.revokeObjectURL(link.href)
          setTimeout(() => {
            this.isDownloadingFile = false
          }, 1000)
        })
        .catch(error => {
          this.isDownloadingFile = false
          console.error(error)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.file {
  padding: 10px 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 150px;
  border: 1px solid;
  position: relative;
  i {
    font-size: 80px;
  }
  .filename {
    width: 100%;
    /* white-space: nowrap; */
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .spinnerContainer {
    position: absolute;
    background: #00000080;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }
}
</style>
