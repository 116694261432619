import { BaseService } from './base.service'

export class FilesService extends BaseService {
  constructor() {
    super()
  }

  getFile(data) {
    const { name } = data || {}
    // if (!name) return
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/get-file/${name}`, { responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  sendMail(data) {
    return new Promise((resolve, reject) => {
      this.nodejsAxiosInstance
        .post('/send-mail', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new FilesService()
